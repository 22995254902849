<template>
   <div class="connectingLines">
        <svg width="750px" height="620px" version="1.1" xmlns="http://www.w3.org/2000/svg">
            
            <!-- Line from dot to story -->
            <line v-if="showline" :x1="story.pos.x" :y1="story.pos.y" x2="730" :y2="top" stroke-width="2" stroke="rgb(255,255,255,0.5)"></line>
            <line v-if="showline" x1="730" :y1="top" x2="750" :y2="top" stroke-width="2" stroke="rgb(255,255,255,0.5)"></line>
            
            <defs>
                <!-- ZENOTOF Colour -->
                <linearGradient id="zenotof7600-true" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color: rgb(36, 151, 67); stop-opacity: 1;"></stop>
                    <stop offset="46%" style="stop-color: rgb(108, 186, 51); stop-opacity: 1;"></stop>
                    <stop offset="90%" style="stop-color: rgb(156, 211, 44); stop-opacity: 1;"></stop>
                </linearGradient>
                <!-- ZENOTOF - Hidden story -->
                <linearGradient id="zenotof7600-false" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                    <stop offset="90%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                </linearGradient>
                
                <!-- OneOmics Colour -->
                <linearGradient id="oneomics-true" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color: rgb(1, 100, 137); stop-opacity: 1;"></stop>
                    <stop offset="46%" style="stop-color: rgb(4, 153, 174); stop-opacity: 1;"></stop>
                    <stop offset="90%" style="stop-color: rgb(8, 204, 198); stop-opacity: 1;"></stop>
                </linearGradient>
                <!-- OneOmics - Hidden story -->
                <linearGradient id="oneomics-false" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                    <stop offset="90%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                </linearGradient>

                
                <!-- BIOPHASE Colour -->
                <linearGradient id="biophase-true" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color: rgb(1, 27, 52); stop-opacity: 1;"></stop>
                    <stop offset="90%" style="stop-color: rgb(0, 96, 192); stop-opacity: 1;"></stop>
                </linearGradient>
                <!-- BIOPHASE - Hidden story -->
                <linearGradient id="biophase-false" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="10%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                    <stop offset="90%" style="stop-color: rgb(255, 255, 255); stop-opacity: 1;"></stop>
                </linearGradient>



                <!-- Hidden Dot lines -->
                <linearGradient id="true" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop style="stop-color: rgb(255, 255, 255); stop-opacity: 0.5;"></stop>
                </linearGradient>
                <linearGradient id="false" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop style="stop-color: rgb(0, 0, 0); stop-opacity: 0.5;"></stop>
                </linearGradient>

                <filter id="f3" x="0" y="0" width="200%" height="200%">
                  <feDropShadow dx="2" dy="2" stdDeviation="2" flood-color="rgba(0,0,0,0.6)"></feDropShadow>
                </filter>
            </defs>

            <!-- Colour Circle -->
                <!-- Static Circle -->
                <circle id="circ" :cx="story.pos.x" :cy="story.pos.y" r="10" :fill="'url(#'+product+'-'+showline+')'" filter="url(#f3)">

                    <animateTransform attributeName="transform" type="translate" dur="1s" :values="'0 0; '+introAnimate.pos+'0 0;'" repeatCount="5"></animateTransform>
                    <animateTransform attributeName="transform" additive="sum" type="scale" dur="1s" :values="'1; 1.5; 1;'" repeatCount="5"></animateTransform>

                </circle>

                <!-- Animated Circle -->
                <circle id="circ2" :cx="story.pos.x" :cy="story.pos.y" r="10" :fill="'url(#'+product+'-'+showline+')'" filter="url(#f3)" :opacity="0.5">

                    <animateTransform attributeName="transform" type="translate" dur="1s" :values="'0 0; '+animate.pos+'0 0;'" repeatCount="indefinite"></animateTransform>
                    <animateTransform attributeName="transform" additive="sum" type="scale" dur="1s" :values="'1; '+animate.scale+'1;'" repeatCount="indefinite"></animateTransform>

                </circle>

                <!-- Animated ToolTip -->
                <g v-if="story.type != 'extonly'" id="toolTip" :transform="'translate('+ toolTipAnimate.x +', '+ toolTipAnimate.y +')'" filter="url(#f3)" :visibility="toolTipAnimate.opacity">
                    <path id="tool-tip-path" d="M75,0 C77.7614237,0 80,2.23857625 80,5 L80,20 C80,22.7614237 77.7614237,25 75,25 L48.191,25 L39.5,33.6923882 L30.807,25 L5,25 C2.23857625,25 0,22.7614237 0,20 L0,5 C0,2.23857625 2.23857625,0 5,0 L75,0 Z" fill-opacity="0.7" fill="#000000"></path>
                    <text id="tool-tip-text" font-size="12" font-weight="normal" fill="#FFFFFF">
                        <tspan x="9" y="17">View internal</tspan>
                    </text>

                    <animateTransform attributeName="transform" type="translate" dur="1s" :values="toolTipAnimate.x+' '+(toolTipAnimate.y + 10)+'; '+toolTipAnimate.x+' '+(toolTipAnimate.y)+';'" repeatCount="1" fill="freeze"></animateTransform>
                </g>
                <!-- <animate xlink:href="#toolTip" attributeName="transform" :from="'translate('+ (story.pos.x - 40) +', '+ (story.pos.y - 45) +')'" :to="'translate('+ (story.pos.x - 40) +', '+ (story.pos.y - 65) +')'" dur="1s" begin="1s" repeatCount="6" fill="freeze"></animate> -->



            <!-- Vertical Lines -->
            <line :x1="story.pos.x" :y1="story.pos.y - 4" :x2="story.pos.x" :y2="story.pos.y - 8" stroke-width="2" stroke-opacity="0.5" :stroke="showline_stroke"></line>
            <line :x1="story.pos.x" :y1="story.pos.y + 4" :x2="story.pos.x" :y2="story.pos.y + 8" stroke-width="2" stroke-opacity="0.5" :stroke="showline_stroke"></line>
            
            <circle :cx="story.pos.x" :cy="story.pos.y" r="1.5" :fill="'url(#'+showline+')'"></circle>
            <!-- Horizontal Lines -->
            <line :x1="story.pos.x - 4" :y1="story.pos.y" :x2="story.pos.x - 8" :y2="story.pos.y" stroke-width="2" stroke-opacity="0.5" :stroke="showline_stroke"></line>
            <line :x1="story.pos.x + 4" :y1="story.pos.y" :x2="story.pos.x + 8" :y2="story.pos.y" stroke-width="2" stroke-opacity="0.5" :stroke="showline_stroke"></line>
            
            <!-- <animate xlink:href="#circ" attributeName="r" from="10" to="15" dur="1s" begin="1s" values="10; 15; 10;" keyTimes="0; 0.5; 1" repeatCount="6" fill="freeze"></animate> -->
        
        </svg>
    </div>
</template>

<script>
export default {
    name: "connectLine",
    props: {
        // x: Number,
        // y: Number,
        // type: String,
        // elemPos: Object,
        storyInLens: String,
        product: String,
        listPos: Number,
        story: Object
    },
    data: function() {
        return {}
    },
    computed: {
        showline: function() {
            if (this.story.type == "hidden") {
                return false
            }
            return true
        },
        showline_stroke: function() {
            if (this.showline) {
                return 'rgb(255, 255, 255)';
            }
            return 'rgb(0, 0, 0)';
        },
        introAnimate: function() {
            return {
                pos: '-'+ this.story.pos.x / 2 +', -'+ this.story.pos.y / 2 +'; '
            };
        },
        toolTipAnimate: function() {
            let opacity = 'hidden';
            if (this.storyInLens == this.story.sid) {
                opacity = 'visible';
            }
            return {
                x: this.story.pos.x - 40,
                y: this.story.pos.y - 45,
                opacity: opacity
            };
        },
        animate: function() {
            if (this.storyInLens == this.story.sid) {
                return {
                    pos: '-'+ this.story.pos.x / 2 +', -'+ this.story.pos.y / 2 +'; ',
                    scale: '1.5; '
                };
            }
            return {
                pos: '0, 0; ',
                scale: '1; '
            };
        },
        width: function() {
            return this.$store.state.productVisual.width
        },
        top: function() {
            return 0 == this.listPos ? 25 : 1 == this.listPos ? 214 : 2 == this.listPos ? 403 : 0
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>