<template>
  <div class="story" style="height: 190px;">
      <div class="storyContent" style="height: 190px;">
          <h3 v-html="storyTitle"></h3>
          <p v-html="story.ex.story"></p>
      </div>


      <div class="button" :class="{'show': lens && story.type != 'extonly'}">
      
          <button  @click="setStory">
              View Internal Lens
              <!-- <span>
                  <span class="button-left"></span>
                  <span class="button-right"></span>
              </span> -->
          </button>

      </div>

  </div>
</template>

<script>
export default {
  name: "storyBlock",
  props: {
      story: Object,
      prod: String,
      intLens: Object
  },
  data: function() {
      return {
          tempHeight: null
      }
  },
  computed: {

      storyTitle() {
          if (this.story.ex.bonus == 'BONUS') {
              return '<strong class="gradBG" style="display: inline-block; margin-right: 5px; padding: 1px 5px 0px;">'+this.story.ex.bonus+'</strong> '+this.story.ex.title;
          }
          return this.story.ex.title;
      },

    //   showButton() {
    //       return lens && this.story.type != 'extonly'
    //   },


      lens: function() {
          return this.intLens.sid == this.story.sid
      },
      storyContent: function() {
          return this.lens ? {
              title: this.story.int.title,
              story: this.story.int.story
          } : {
              title: this.story.ex.title,
              story: this.story.ex.story
          }
      },
      pos: function() {
          var t = document.getElementById("frame").getBoundingClientRect().y;
          return {
              top: this.$refs.block.getBoundingClientRect().y - t,
              height: this.$refs.block.getBoundingClientRect().height
          }
      },
      storyNum: function() {
          return this.story.sid.replace(/story/g, "")
      },
      showStory: function() {
          return this.$store.state.viewingStory == this.story.sid
      },
      height: function() {
          return this.showStory ? "calc(" + this.$refs.para.getBoundingClientRect().height + "px + 1.8rem); background-color:rgba(0,0,0,0.9)" : "190px;"
      },
      contentHeight: function() {
          return this.showStory ? "calc(" + this.$refs.para.getBoundingClientRect().height + "px + 1.8rem);" : "190px;"
      }
  },
  methods: {
      setStory(){
          this.$store.commit("updateStoryView", this.story);
          this.$emit('viewInternalStory', this.story.sid);
      },
      toggleView: function() {
          this.$store.commit("toggleStoryView", {
              prod: this.prod,
              story: this.story.sid
          })
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.story { 
    width: 100%;
    transition:all 500ms ease-in;
    background-color: rgba(0,0,0,.2);
}
.storyContent{
    padding:.9rem
}
/* #stories .story.active .storyContent {
    overflow-y:scroll
} */
#stories .story.active {
    transform-origin: top right;
    transform: scale(1.05);
    border-left: 5px solid #FFFFFF;
}

#stories .story.active,
#stories .story.active .storyContent {
    height: auto !important;
    min-height: 190px;
    z-index: 15 !important;
}



h3{
    font-size:1.15rem;
    margin-bottom:.3rem
}
h3,p{
    font-family:Geogrotesque-Rg,Helvetica,sans-serif
}
p{
    font-size:.9rem;
    line-height: 1.22;
    letter-spacing:.1px;
    margin-top:.2rem
}
p:last-child{
    padding-bottom:1rem
}
h3 strong {
    display: inline-block;
    margin-right: 5px;
    padding: 0px 5px;
}
.button{
    text-align:right;
    padding:1rem 0rem 0rem .2rem;
    width:100%;
    position:fixed;
    bottom:0;
    right:0;
    background:linear-gradient(0deg,rgba(0,0,0,.6),transparent);
    opacity: 0;
    pointer-events: none;

    transition: all 500ms;

}
.button.show {
    opacity: 1;
    pointer-events: all;
}
.button button{
    position:relative;
    background:none;
    width:130px;
    height:30px;
    border:0;
    outline:0 solid hsla(0,0%,100%,.3);
    background:rgba(0,0,0,.4);
    font-size: 0.8rem;
    font-family:Geogrotesque-Rg,Helvetica,sans-serif;
    color: #FFFFFF;
    cursor: pointer;
    border-left: 1px solid #FFFFFF;
    border-top: 1px solid #FFFFFF;
}
.button button:hover {
    background:rgba(255,255,255,.4);
    color: #000000;
}
.button button:focus{
    outline:2px solid hsla(0,0%,100%,.3);
    background:hsla(0,0%,100%,.3)
}
.button span{
    display:block;
    color:#fff
}
.button span.button-left,.button span.button-right{
    border-top:1px solid;
    transform:rotate(45deg);
    transform-origin:center;
    position:absolute;
    top:15px;
    width:12px;
    left:5px;
    transition:all .3s ease-in-out
}
.button span.button-right{
    transform:rotate(-45deg);
    left:13px
}
.active .button span.button-left,.active .button span.button-right{
    top:15px;
    width:20px;
    left:5px
}
</style>
